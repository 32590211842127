<template>
    <div>
        <section class="artist">
            <div class="inner height_half_inner">
                <header class="section_header header_flex">
                    <h2 class="text_xxl text_white text_300">{{artista.name}}</h2>
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <router-link to="/" class="breadcrumbs_item">Home<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <router-link to="/artistas" class="breadcrumbs_item">Artistas<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg></router-link>
                            </li>
                            <li>
                                <span class="breadcrumbs_item">{{artista.name}}</span>
                            </li>
                        </ul>
                    </div>
                </header>
                <div class="content_block padded rounded white">
                    <div class="product_sheet grid grid_pad flex_space">
                        <div class="grid_col grid_col_1_3">
                            <div class="grid_col_box">
                                <figure>
                                    <img :src="artista.img" @error="artista.img = require('@/assets/images/img_void.jpg')">
                                </figure>
                            </div>
                        </div>
                        <div class="grid_col grid_col_2_3">
                            <div class="grid_col_box">
                               <h3 class="text_l mb30">{{artista.name}}</h3>
                               <div class="product_description" v-html="artista.description"></div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="products">
            <div class="inner height_half_top_inner height_bottom_inner">
                <div class="content_block padded white">
                    <header class="section_header header_flex">
                        <h2 class="text_xl text_red text_300">Productos del artista <b>{{artista.name}}</b></h2>
                        <router-link :to="`/productos?artist=${artista.id}`" class="btn small graphic_right local_color">Ver todos<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path></svg></router-link>
                    </header>
                    <div class="product_list grid__motor grid__motor_col_5">
                        <article class="product_item" v-for="product in productos" :key="product.id">
                            <Producto :product="product"/>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios'
import Producto from '@/components/Producto.vue'

export default {
    name: 'Login',
    data: function(){
        return {
            artista: {},
            productos: []
        }
    },
    components:{
        Producto
    },
    methods: {
        get: function(slug){
            axios
                .get(process.env.VUE_APP_URL+`artists/${slug}`)
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.artista = rs.data;
                        if(!this.artista.img)
                            this.artista.img = require('@/assets/images/img_void.jpg');
                        this.getProductos();
                    }
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status == 404)
                            this.$router.push('/404');
                    }
                    else
                        this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion';
                })
        },
        getProductos: function(){
            axios
                .get(process.env.VUE_APP_URL+`products`, {
                    params:{
                        random: 1,
                        artist: this.artista.id,
                        limit: 5
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.productos = rs.data;
                    }
                })
                .catch(error => this.$parent.error = 'Hubo un error de conexion. intentelo mas tarde o contacte con administracion')
        }
    },
    created: function(){
        this.get(this.$route.params.slug);
    },
    beforeRouteUpdate (to, from, next) {
		this.get(to.params.slug);
		next();
	}
}
</script>